<template>
  <div class="searchMenuParent">
    <router-link class="companyLogo" :to="$i18nRoute({ name: 'Overview' })">
      <img src="@/assets/zeck_logo.png" />
    </router-link>
    <form @submit="fetchSearchResults">
      <input
        required
        class="search-box"
        type="text"
        v-bind:placeholder="$t('searchMenu.search')"
        v-model="searchInput"
        v-on:input="fetchSearchResults"
      />
      <button
        class="close-icon"
        type="reset"
        v-on:click="cancelSearch"
      ></button>
    </form>
    <template v-if="searchResults == null">
      <router-link
        class="searchMenuButton"
        :to="$i18nRoute({ name: 'Overview' })"
      >
        {{ $t("searchMenu.overview") }}
      </router-link>
      <router-link
        class="searchMenuButton"
        :to="$i18nRoute({ name: 'CreateTicket' })"
      >
        {{ $t("searchMenu.newTicket") }}
      </router-link>
      <div class="filler"></div>
      <router-link
        class="searchMenuButton"
        :to="$i18nRoute({ name: 'Settings' })"
      >
        {{ $t("general.settings") }}
      </router-link>
      <a class="searchMenuButton" v-on:click="logout()">
        <p class="loggedInAs" v-if="user">
          {{ $t("searchMenu.loggedInAs") }}: {{ user.name }}
        </p>
        {{ $t("searchMenu.logout") }}
      </a>
    </template>
    <div class="searchResultContainer" v-if="searchResults != null">
      <div
        v-for="searchResult in searchResults"
        :key="searchResult.primaryKey"
        v-on:click="clickSearchResult(searchResult)"
        tag="span"
      >
        <div class="searchResultItem">
          {{ searchResult.subject }} <br />
          <!-- Message-ID: {{ searchResult.PrimaryKey }} <br /> -->
          Ticket Code:
          {{
            searchResult.ticketProcessPrimaryKey
              ? searchResult.ticketProcessPrimaryKey
              : searchResult.primaryKey
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import userFunctions from "@/functions/user";
import ticketFunctions from "@/functions/ticket";

export default {
  name: "SearchMenu",
  data() {
    return {
      searchInput: "",
      searchResults: null,
      user: null,
    };
  },
  methods: {
    // sendSearchString(event) {
    //   if (event.target.value.length >= 3) {
    //     this.$root.$emit("search", event.target.value);
    //   }
    // },
    async fetchSearchResults(event) {
      // this.$store.dispatch(LOADING_STARTED);

      event.preventDefault();
      if (event.type && event.type == "submit") {
        event.preventDefault();
        return;
      }

      if (this.searchInput.length >= 1) {
        const searchResults = await ticketFunctions.searchTicket(
          this.searchInput
        );

        // console.log("searchResults:", searchResults);

        // group search results for the same TicketProcess together
        var filteredSearchResults = [];
        for (const searchResult of searchResults) {
          var primaryKey = searchResult.ticketProcessPrimaryKey
            ? searchResult.ticketProcessPrimaryKey
            : searchResult.primaryKey;
          var foundFilteredSearchResults = filteredSearchResults.filter(
            (fSearchResult) => {
              var fPrimaryKey = fSearchResult.ticketProcessPrimaryKey
                ? fSearchResult.ticketProcessPrimaryKey
                : fSearchResult.primaryKey;
              return fPrimaryKey == primaryKey;
            }
          );
          if (foundFilteredSearchResults.length == 0) {
            filteredSearchResults.push(searchResult);
          }
        }

        this.searchResults = filteredSearchResults;
        // console.log("this.searchResults:", this.searchResults);
      } else {
        this.searchResults = null;
      }

      // this.$store.dispatch(LOADING_STOPPED);
    },
    clickSearchResult(searchResult) {
      // if wanted to cancel search after select a searchResult
      //this.cancelSearch()

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const factor = 0.75;
      const width = screenWidth * factor;
      const height = screenHeight * factor;
      const left = (screenWidth - width) / 2;
      const top = (screenHeight - height) / 2;
      
      let routeData = this.$router.resolve(
        this.$i18nRoute({
          name: "Ticket",
          params: {
            processId: searchResult.ticketProcessPrimaryKey
              ? searchResult.ticketProcessPrimaryKey
              : searchResult.primaryKey,
          },
        })
      );
      // open in new window
      window.open(
        routeData.href,
        "_blank",
        "height="+height+",width="+width+",left="+left+",top="+top+""
      );
    },
    cancelSearch() {
      this.searchInput = "";
      this.searchResults = null;
    },
    async getUser() {
      this.$store.dispatch(LOADING_STARTED);
      let tmpUser = await userFunctions.getUsers([this.$store.getters.userId]);
      this.$store.dispatch(LOADING_STOPPED);

      if (!tmpUser) {
        console.error("getUser: tmpUser is null");
        return;
      }

      this.user = tmpUser[0];
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
      this.$router.push(this.$i18nRoute({ name: "Login" }));
    },
  },
  beforeMount: function () {
    this.getUser();
  },
};
</script>

<style lang="scss" scoped>
.searchMenuParent {
  background: #333;
  height: 100%;
}

.search-box,
.close-icon,
.search-wrapper {
  position: relative;
  padding: 10px;
}
.search-wrapper {
  width: 500px;
  margin: auto;
  margin-top: 50px;
}
.search-box {
  width: 85%;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 15px;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
}
.search-box:focus {
  box-shadow: 0 0 15px 5px #b0e0ee;
  border: 2px solid #bebede;
}
.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
  content: "X";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: #fa9595;
  z-index: 1;
  right: 35px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0 0 2px #e50f0f;
  cursor: pointer;
}
.search-box:not(:valid) ~ .close-icon {
  display: none;
}

.filler {
  height: 100%;
}

.searchMenuButton {
  cursor: pointer;
  display: block;
  padding: 1em;
  text-decoration: none;
  color: #e4e4e4;
  transition: 0.1s;
  border-top: 1px solid #444;
}

.searchMenuButton:hover {
  background: #444;
}

.searchResultContainer {
  overflow-y: auto;
}

.searchResultItem {
  cursor: pointer;
  background-color: white;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 1em;
}

.loggedInAs {
  font-size: x-small;
  color: gray;
  margin-top: -10px;
  margin-bottom: 2px;
}

.router-link-exact-active {
  background: #286145 !important;
}
.router-link-exact-active.companyLogo {
  background: none !important;
}

.companyLogo {
  padding: 1em;
  width: 100%;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}
</style>