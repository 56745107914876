var utilFunctions = {
    delay(ms) {
        return new Promise(res => setTimeout(res, ms));
    },
    isHTML(str) {
        let re = /^(<.+>)/;
        return str.match(re);
    },
    isEmailAdressValid(emailAddress) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailAddress.match(re);
    },
    checkEmailAdress(target, errorMessage) {
        if (!target || !target.value) {
            target.setCustomValidity(errorMessage);
            target.reportValidity();
            return false;
        }

        var value = target.value;
        if (!this.isEmailAdressValid(value)) {
            target.setCustomValidity(errorMessage);
            target.reportValidity();
            return false;
        } else {
            target.setCustomValidity("");
            target.reportValidity();
            return true;
        }
    },
}

export default utilFunctions;