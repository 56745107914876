import Vue from 'vue'
import VueRouter from 'vue-router'
import Overview from '../views/Overview.vue'
import store from '../store'
import { Trans } from '@/plugins/Translation'

Vue.use(VueRouter)

// for sub pages use route level code-splitting
// which generates a separate chunk for the route
// which is lazy-loaded when the route is visited.
function load(component) {
  // '@' is aliased to src
  return () => import(`@/views/${component}.vue`) // /* webpackChunkName: "${component}" */
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  // next("/");
  router.push(Trans.i18nRoute({name: "Overview"}));
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  // next("/login");
  router.push(Trans.i18nRoute({name: "Login"}));
};

const routes = [{
  path: '/:locale',
  component: {
    template: "<router-view></router-view>"
  },
  beforeEnter: Trans.routeMiddleware,
  children: [{
    path: '/',
    name: 'Overview',
    component: Overview,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/:locale/login",
    name: "Login",
    component: load("Login"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/:locale/ticket/:processId',
    name: 'Ticket',
    component: load("Ticket"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/:locale/createticket',
    name: 'CreateTicket',
    component: load("CreateTicket"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/:locale/settings',
    name: 'Settings',
    component: load("Settings"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/:locale/imprint',
    name: 'Imprint',
    component: load("Imprint"),
  },
  {
    path: '/:locale/dataprotection',
    name: 'Dataprotection',
    component: load("Dataprotection"),
  },
  {
    path: '/:locale/agb',
    name: 'Agb',
    component: load("Agb"),
  },
  {
    path: '/:locale/copyright',
    name: 'Copyright',
    component: load("Copyright"),
  },
  {
    path: '/:locale/:catchAll(.*)',
    name: 'NotFound',
    component: load("NotFound"),
  }
  ]
},
{
  path: '*',
  redirect() {
    return Trans.getUserSupportedLocale();
  }
}
]

const router = new VueRouter({
  mode: 'history', // removes the "#" from the URL
  routes
})

export default router
