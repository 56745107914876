import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import utils from '@/functions/utils';

var poolFunctions = {
    async getPoolsByFilter(filter, store) {
        store.dispatch(LOADING_STARTED);

        var pools = null;

        await axios
            .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_POOL_BY_FILTER + filter)
            .then((resp) => {
                pools = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 500) {
                    error.handleGlobally && error.handleGlobally();
                } else if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        // if no pools, try it again after some time, because first try after login is 401
        if (!pools) {
            await utils.delay(500);
            await axios
                .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_POOL_BY_FILTER + filter)
                .then((resp) => {
                    pools = resp.data;
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        store.dispatch(LOADING_STOPPED);

        return pools;
    }
}

export default poolFunctions;