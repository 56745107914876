import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";

var customerFunctions = {
    async getCustomerByEmailAdress(emailAddress) {
        let customer = null;

        await axios
            .get(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/customer/byEmailAddress/" + emailAddress
            )
            .then((resp) => {
                // console.log("getCustomerByEmailAdress resp: ", resp);
                customer = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode === 400) {
                    // console.log("Customer doesn't exit");
                } else {
                    error.handleGlobally && error.handleGlobally();
                }
            });

        return customer;
    },
    async addCustomer(customer, store) {
        store.dispatch(LOADING_STARTED);

        let success = false;

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/customer/add",
                customer
            )
            .then(() => {
                // console.log("addCustomer resp: ", resp);
                success = true;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return success;
    },
    async updateCustomer(customer, store) {
        store.dispatch(LOADING_STARTED);

        let success = false;

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/customer/savechanges",
                customer
            )
            .then(() => {
                // console.log("updateCustomer resp: ", resp);
                success = true;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return success;
    },
    buildNameForCustomer(customer) {
        let tmpName = "";

        if (!customer) {
            return "";
        }

        if (customer.firstName) {
            tmpName = customer.firstName;
        }
        if (customer.name) {
            if (tmpName) {
                tmpName += " ";
            }
            tmpName += customer.name;
        }
        if (customer.organisation) {
            tmpName += " (" + customer.organisation + ")";
        }

        return tmpName;
    }
}

export default customerFunctions;