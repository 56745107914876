<template>
  <div id="spinnerBackground">
    <div id="spinner"></div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#spinnerBackground {
  height: 100%;
  width: 100%;
  background: rgb(23, 22, 22);
  opacity: 0.8;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9998;
}

#spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #286145;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>