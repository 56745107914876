import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import 'es6-promise/auto'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faEdit,
  faPaperPlane,
  faSave,
  faPlus,
  faBars,
  faEllipsisV,
  faChevronDown,
  faChevronRight,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faRulerHorizontal,
  faUndoAlt,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'

// add the icons we want to use
library.add(
  faEdit,
  faPaperPlane,
  faSave,
  faPlus,
  faBars,
  faEllipsisV,
  faChevronDown,
  faChevronRight,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteRight,
  faRulerHorizontal,
  faUndoAlt,
  faRedoAlt,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

import "@/assets/styles/global.scss"

// auto set the token after refresh
const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token
}

import { AUTH_LOGOUT } from "@/store/actions/auth"
import userFunctions from '@/functions/user'
import { i18n } from './i18n'
import { Trans } from './plugins/Translation'

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

import Toast from 'vue-easy-toast'

Vue.use(Toast)

// errorComposer will compose a handleGlobally function
const errorComposer = (error) => {
  return () => {
    console.error("errorComposer: ", error, error.response)

    const statusCode = error.response ? error.response.status : null
    // if (statusCode === 404) {
    // notifier.error('The requested resource does not exist or has been deleted')
    // }
    if (statusCode == 400) {
      Vue.toast(error.response.data ?? "An Error occured", {
        horizontalPosition: "center",
        className: ["et-alert"],
        duration: 5000,
        closeable: true,
        transition: "slide-down"
      })
    }

    if (statusCode === 401) {
      console.log("main: Got logged out because token is no longer valid")
      store.dispatch(AUTH_LOGOUT)
      router.push(this.$i18nRoute({ name: "Login" }))
      Vue.toast("Got logged out because token is no longer valid", {
        horizontalPosition: "center",
        className: ["et-alert"],
        duration: 5000,
        closeable: true,
        transition: "slide-down"
      })
    }

    if (statusCode === 500) {
      // console.error(error, error.response);
      userFunctions.validUser(store, router, this.$i18nRoute({ name: "Login" }))
    }
  }
}

axios.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorComposer(error)

  return Promise.reject(error)
})

new Vue({
  router,
  store,

  computed: {
    showMenuNew() {
      return this.$store.state.showMenu
    }
  },

  i18n,
  render: h => h(App)
}).$mount("#app")
