<template>
  <div class="overviewParent">
    <hidden :md="true" :lg="true" :xl="true">
      <select
        id="filterSelect"
        class="subMenuSelect"
        name="filterSelect"
        @change="selectFilter($event.target.value)"
      >
        <option
          v-for="(filterElement, key) in filterElements"
          v-bind:key="key"
          :value="key"
        >
          {{ filterElement }} ({{ getCountOfFilter(key) }})
        </option>
      </select>
      <select
        v-if="poolFilters.length > 1"
        id="poolFilterSelect"
        class="subMenuSelect"
        name="poolFilterSelect"
        @change="filterTableByPool($event.target.value)"
        :value="selectedPoolFilter"
      >
        <option value="all">Aus allen Pools</option>
        <option
          v-for="(poolFilter, key) in poolFilters"
          v-bind:key="key"
          :value="poolFilter"
        >
          {{ poolFilter }}
        </option>
      </select>
    </hidden>
    <div id="filterAndTable">
      <hidden :xs="true" :sm="true" id="filter">
        <div class="filteWrapper">
          <div v-for="(filterElement, key) in filterElements" v-bind:key="key">
            <a :id="key" @click="selectFilter(key)">
              {{ $t("overview.filters." + key) }}
              <span class="countOfFilter">{{ getCountOfFilter(key) }}</span>
            </a>
            <div v-if="currentFilter == key && poolFilters.length > 1">
              <a
                class="poolFilter"
                v-for="(poolFilter, key) in poolFilters"
                :key="key"
                :id="poolFilter"
                @click="filterTableByPool(poolFilter)"
              >
                {{ poolFilter }}
              </a>
            </div>
          </div>
        </div>
      </hidden>
      <div id="grid-wrapper">
        <div class="spinner" id="refreshDataSpinner"></div>
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-alpine"
          id="myGrid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          @columnMoved="onColumnMoved"
          @sortChanged="onSortChanged"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          @rowClicked="onRowClicked"
          :localeText="localeText"
          :rowData="editedRowData ? editedRowData : rowData"
          @first-data-rendered="onFirstDataRendered"
          @grid-size-changed="onGridSizeChanged"
          @bodyScroll="handleGridScroll"
        ></ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { Hidden } from "vue-grid-responsive";

import { AgGridVue } from "../../node_modules/ag-grid-vue";

import { Trans } from "@/plugins/Translation";

import { AG_GRID_LOCALE_DE } from "../localization/locale.de";
import { AG_GRID_LOCALE_EN } from "../localization/locale.en";

// import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import processFunctions from "@/functions/process";
import userFunctions from "@/functions/user";
import poolFunctions from "@/functions/pool";
import customerFunctions from "@/functions/customer";

// Vue.component("row", Row);
// Vue.component("column", Column);
Vue.component("hidden", Hidden);

const filterElements = {
  All: "All",
  NotAssignedAndOpen: "Nicht zugewiesen und offen",
  Open: "Offen",
  AssignedToMe: "Meine zugewiesenen Tickets",
  WaitForDelivery: "Warten auf Auslieferung erreicht",
  MyWaitReached: "Meine warten erreicht Tickets",
  WaitReached: "Warten erreicht",
  // Escalated: "Eskaliert",
  Closed: "Geschlossen",
};

export default {
  name: "Overview",
  data() {
    return {
      defaultFilter: "AssignedToMe",
      caching: false,
      cacheTimeMin: 2,
      refreshTimerMin: 5,
      allProcesses: [],
      filterElements,
      filterCountOfs: [],
      currentFilter: null,
      poolFilters: [],
      selectedPoolFilter: "all",
      gridOptions: null,
      columnDefs: null,
      defaultColDef: null,
      rowData: null,
      editedRowData: null,
      localeText: null,
      users: [],
      pools: [],
      customers: new Map(),
      refreshTimer: null,
      lastLoadDateMS: null,
    };
  },
  components: {
    Hidden,
    AgGridVue,
  },
  methods: {
    onRowClicked(params) {
      var primaryKey = params.node.data.primaryKey;

      // was clcked, so remove that it was new
      localStorage.removeItem(primaryKey + "wasNew");
      params.node.data.new = false;

      if (this.gridOptions.api) this.gridOptions.api.redrawRows();

      // navigate in same tab
      // this.$router.push(
      //   this.$i18nRoute({ name: "Ticket", params: { processId: primaryKey } })
      // );

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const factor = 0.75;
      const width = screenWidth * factor;
      const height = screenHeight * factor;
      const left = (screenWidth - width) / 2;
      const top = (screenHeight - height) / 2;

      let routeData = this.$router.resolve(
        this.$i18nRoute({
          name: "Ticket",
          params: { processId: primaryKey },
        })
      );
      // open in new window
      window.open(
        routeData.href,
        "_blank",
        "height="+height+",width="+width+",left="+left+",top="+top+""
      );
    },
    getRefreshDataSpinner() {
      return document.getElementById("refreshDataSpinner");
    },
    startLoading() {
      this.getRefreshDataSpinner().style.display = "block";
    },
    stopLoading() {
      this.getRefreshDataSpinner().style.display = "none";
    },
    async getCountOfFilters() {
      this.filterCountOfs = await processFunctions.getCountOfFilters();
    },
    getCountOfFilter(filter) {
      if (!this.filterCountOfs || this.filterCountOfs.length < 1) return 0;

      let countOfFilter = this.filterCountOfs.find(
        (count) => count.ticketFilter == filter
      );

      if (!countOfFilter) return 0;

      return countOfFilter.countOf;
    },
    async refreshTable() {
      await this.getCountOfFilters();
      await this.filterTable(this.currentFilter, true);
      this.filterTableByPool(this.selectedPoolFilter);
    },
    async selectFilter(filter) {
      await this.filterTableWithLoading(filter);
      this.filterTableByPool("all");
    },
    async filterTableWithLoading(filter) {
      this.startLoading();
      await this.filterTable(filter);
      this.stopLoading();
    },
    async filterTable(filter, refresh) {
      if (filter != "") {
        if (!refresh) {
          this.resetPoolFilter();
          if (filter == this.currentFilter) {
            return;
          }
        }

        this.currentFilter = filter;
        localStorage.setItem("lastSelectedFilter", this.currentFilter);

        var currentFilterElements =
          document.getElementsByClassName("currentFilter");
        for (const currentFilterElement of currentFilterElements) {
          if (currentFilterElement) {
            currentFilterElement.classList.remove("currentFilter");
          }
        }
        let currentFilterElement = document.getElementById(filter);
        if (currentFilterElement) {
          currentFilterElement.classList.add("currentFilter");
        }
        let filterSelectElement = document.getElementById("filterSelect");
        if (filterSelectElement) {
          filterSelectElement.value = filter;
        }

        var processes;

        if (this.caching && this.allProcesses) {
          // filter local
          var now = new Date();
          now.setHours(23);
          now.setMinutes(59);
          now.setSeconds(59);
          var curTimesSec = Math.floor(now.getTime() / 1000);

          switch (filter) {
            case "All":
              processes = this.allProcesses;
              break;
            case "NotAssignedAndOpen":
              processes = this.allProcesses.filter((process) => {
                return process.state != "Done" && process.owner <= 0;
              });
              break;
            case "Open":
              processes = this.allProcesses.filter((process) => {
                return process.state != "Done";
              });
              break;
            case "AssignedToMe":
              processes = this.allProcesses.filter((process) => {
                return (
                  process.state != "Done" &&
                  process.owner == this.$store.getters.userId
                );
              });
              break;
            case "WaitForDelivery":
              processes = this.allProcesses.filter((process) => {
                return (
                  process.state == "WaitForDelivery" &&
                  process.followUp > 0 &&
                  process.followUp <= curTimesSec
                );
              });
              break;
            case "MyWaitReached":
              processes = this.allProcesses.filter((process) => {
                return (
                  process.state != "Done" &&
                  process.owner == this.$store.getters.userId &&
                  process.followUp > 0 &&
                  process.followUp <= curTimesSec
                );
              });
              break;
            case "WaitReached":
              processes = this.allProcesses.filter((process) => {
                return (
                  process.state != "Done" &&
                  process.followUp > 0 &&
                  process.followUp <= curTimesSec
                );
              });
              break;
            case "Escalated":
              // TODO
              processes = null;
              break;
            case "Closed":
              processes = this.allProcesses.filter((process) => {
                return process.state == "Done";
              });
              break;
            default:
              processes = this.allProcesses;
              break;
          }
        } else {
          // let the api filter
          // console.log("The api filters with filter:", filter);
          processes = await processFunctions.getProcessesByFilter(filter);
        }

        await this.populateOverviewTable(processes);

        // filter by pool
        var lastSelectedPoolFilter = localStorage.getItem(
          "lastSelectedPoolFilter"
        );
        if (lastSelectedPoolFilter) {
          this.filterTableByPool(lastSelectedPoolFilter);
        }

        // scroll to last know position
        this.resetScrollPosition();
      } else {
        console.error("Api endpoint not implemented yet...");
      }
    },
    resetPoolFilter() {
      // console.log("resetPoolFilter");
      this.editedRowData = null;
      var currentPoolFilterElements =
        document.getElementsByClassName("currentPoolFilter");
      for (const currentPoolFilterElement of currentPoolFilterElements) {
        if (currentPoolFilterElement) {
          currentPoolFilterElement.classList.remove("currentPoolFilter");
        }
      }
      this.selectedPoolFilter = "all";
    },
    filterTableByPool(pool) {
      this.resetPoolFilter();

      localStorage.setItem("lastSelectedPoolFilter", pool);

      // if all than don't filter, show tickets from all pools
      if (pool == "all") {
        return;
      }
      // console.log("filterTableByPool pool", pool);
      this.editedRowData = this.rowData.filter((process) => {
        return process.poolName == pool;
      });
      // console.log("rowData: ", this.rowData);

      document.getElementById(pool).classList.add("currentPoolFilter");
      this.selectedPoolFilter = pool;
    },
    async populateOverviewTable(processes) {
      if (processes) {
        processes = await this.changeProcessesData(processes);
        this.rowData = processes;
        var currentLoadDateMS = localStorage.getItem("currentLoadDateMS");
        if (currentLoadDateMS) {
          localStorage.setItem("lastLoadDateMS", currentLoadDateMS.toString());
          // remove if when remove row-new class on every reload
          if (!this.lastLoadDateMS)
            this.lastLoadDateMS = parseInt(currentLoadDateMS);
        }
        localStorage.setItem("currentLoadDateMS", new Date().getTime());
        await this.loadCustomerNames(this.rowData);
      } else {
        this.rowData = null;
      }
    },
    async changeProcessesData(processes) {
      // console.log(
      //   "changeProcessesData processes: ",
      //   processes
      // );
      if (!processes) {
        return null;
      }

      this.poolFilters = [];

      // console.log("customers:", this.customers);

      for (const process of processes) {
        if (!process.ownerName) {
          if (this.users) {
            var owner = this.users.find((user) => {
              return user.id == process.owner;
            });
            if (owner) {
              process.ownerName = owner.name;
            } else {
              process.ownerName = "";
            }
          }
        }

        if (!process.poolName || process.poolName == "Unbekannter Pool") {
          if (this.pools) {
            var pool = this.pools.find((pool) => {
              return pool.primaryKey == process.poolPrimaryKey;
            });
            if (pool) {
              process.poolName = pool.name;
            } else {
              process.poolName = "Unbekannter Pool";
            }
          }
        }

        // add poolNames if there are not already added
        if (
          process.poolName != "Unbekannter Pool" &&
          !this.poolFilters.includes(process.poolName)
        ) {
          this.poolFilters.push(process.poolName);
        }

        var wasNew = localStorage.getItem(process.primaryKey + "wasNew");

        if (
          wasNew ||
          (this.lastLoadDateMS &&
            (process.creationDate * 1000 > this.lastLoadDateMS ||
              process.lastChangeDate * 1000 > this.lastLoadDateMS))
        ) {
          localStorage.setItem(process.primaryKey + "wasNew", "true");
          process.new = true;
        }
      }

      return processes;
    },
    async loadCustomerNames(processes) {
      if (!processes) {
        return null;
      }

      for (let i = processes.length - 1; i >= 0; i--) {
        const process = processes[i];
        if (!process.customerName && process.ticketSender) {
          let customer = this.customers.get(process.ticketSender.emailAddress);
          // console.log("customer:", customer);
          if (!customer) {
            // console.log("No customer for", process.ticketSender.emailAddress, "found, load it.");
            customer = await customerFunctions.getCustomerByEmailAdress(
              process.ticketSender.emailAddress
            );
          }

          // add customers if the are not already added
          if (!this.customers.get(process.ticketSender.emailAddress)) {
            // console.log(process.ticketSender.emailAddress, "not inclued, add it.");
            if (!customer) {
              // create a customer object for the not found onces
              customer = new Object();
              customer.emailAddress = process.ticketSender.emailAddress;
            }
            this.customers.set(process.ticketSender.emailAddress, customer);
            // save customers for caching
            // console.log("save customers:", this.customers);
            localStorage.setItem(
              "allCustomers",
              JSON.stringify(Array.from(this.customers.entries()))
            );
            localStorage.setItem(
              "lastLoadCustomers",
              new Date().getTime().toString()
            );
          }

          if (customer) {
            process.customerName =
              customerFunctions.buildNameForCustomer(customer);
          } else {
            process.customerName = " ";
          }
          if (this.gridOptions.api) this.gridOptions.api.refreshCells();
        }
      }
    },
    async loadAllProcesses() {
      if (this.caching) {
        var lastLoadMS = localStorage.getItem("lastLoadProcesses");
        var age = 0;
        var processes = null;

        if (lastLoadMS) {
          lastLoadMS = parseInt(lastLoadMS);
          var curMS = new Date().getTime();
          age = curMS - lastLoadMS;
        }

        if (lastLoadMS && age < this.cacheTimeMin * 60 * 1000) {
          // console.log("use cached processes, age in sec:", age / 1000);
          processes = JSON.parse(localStorage.getItem("allProcesses"));
        } else {
          // console.log("processes cache is to old or doesnt't exists yet");
        }

        if (!processes) {
          // console.log("refresh all processes from api");
          this.startLoading();
          processes = await processFunctions.getProcessesByFilter("All");
          this.stopLoading();
          localStorage.setItem(
            "lastLoadProcesses",
            new Date().getTime().toString()
          );
          localStorage.setItem("allProcesses", JSON.stringify(processes));
        }
        this.allProcesses = processes;
      }
    },
    async loadCustomers() {
      if (this.caching) {
        var lastLoadMS = localStorage.getItem("lastLoadCustomers");
        var age = 0;

        if (lastLoadMS) {
          lastLoadMS = parseInt(lastLoadMS);
          var curMS = new Date().getTime();
          age = curMS - lastLoadMS;
        }

        if (lastLoadMS && age < this.cacheTimeMin * 60 * 1000) {
          this.customers = new Map(
            JSON.parse(localStorage.getItem("allCustomers"))
          );
          // console.log("use cached customers, age in sec:", age / 1000);
        } else {
          // console.log("customers cache is to old or doesnt't exists yet");
        }
      }
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged(params) {
      // only show the colums that fit
      // var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
      // var columnsToShow = [];
      // var columnsToHide = [];
      // var totalColsWidth = 0;
      // var allColumns = params.columnApi.getAllColumns();
      // for (var i = 0; i < allColumns.length; i++) {
      //   var column = allColumns[i];
      //   totalColsWidth += column.getMinWidth();
      //   if (totalColsWidth > gridWidth) {
      //     columnsToHide.push(column.colId);
      //   } else {
      //     columnsToShow.push(column.colId);
      //   }
      // }
      // params.columnApi.setColumnsVisible(columnsToShow, true);
      // params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
    },
    async onGridReady(params) {
      var columnState = JSON.parse(localStorage.getItem("columnState"));
      if (columnState) {
        params.columnApi.setColumnState(columnState);
      }

      var sortModel = JSON.parse(localStorage.getItem("sortModel"));
      if (sortModel) {
        params.api.setSortModel(sortModel);
      }

      // load the things once
      this.users = await userFunctions.getAppUsers(this.$store);
      this.pools = await poolFunctions.getPoolsByFilter("All", this.$store);

      // load all processes, local or from api
      await this.loadAllProcesses();
      // load customer from local if there are not to old
      await this.loadCustomers();

      // filter table
      var filter = localStorage.getItem("lastSelectedFilter");
      if (!filter) {
        filter = this.defaultFilter;
      }
      await this.filterTableWithLoading(filter);

      this.refreshTimer = setInterval(
        this.refreshTable,
        this.refreshTimerMin * 60 * 1000
      );
    },
    onColumnMoved(params) {
      var columnState = JSON.stringify(params.columnApi.getColumnState());
      localStorage.setItem("columnState", columnState);
    },
    onSortChanged(params) {
      let sortModel = JSON.stringify(params.api.getSortModel());
      localStorage.setItem("sortModel", sortModel);
    },
    handleGridScroll() {
      this.saveScrollPosition();
    },
    getGridViewPort() {
      var grid = document.getElementById("myGrid");
      if (!grid) return null;
      var viewport = grid.querySelector(".ag-body-viewport");
      if (!viewport) return null;
      return viewport;
    },
    saveScrollPosition() {
      var gridViewPort = this.getGridViewPort();
      if (!gridViewPort) return;

      if (gridViewPort.scrollTop == 0) return;

      localStorage.setItem("lastScrollPosition", gridViewPort.scrollTop);
    },
    resetScrollPosition() {
      var gridViewPort = this.getGridViewPort();
      if (!gridViewPort) return;

      var lastScrollPosition = localStorage.getItem("lastScrollPosition");

      if (lastScrollPosition) {
        gridViewPort.scrollTop = lastScrollPosition;
      }
    },
  },
  beforeMount() {
    // eslint-disable-next-line no-undef
    if (Trans.currentLocale == "de" && AG_GRID_LOCALE_DE) {
      // eslint-disable-next-line no-undef
      this.localeText = AG_GRID_LOCALE_DE;
      // eslint-disable-next-line no-undef
    } else if (Trans.currentLocale == "en" && AG_GRID_LOCALE_EN) {
      // eslint-disable-next-line no-undef
      this.localeText = AG_GRID_LOCALE_EN;
    }

    var tmpLastLoadDateMS = localStorage.getItem("lastLoadDateMS");
    if (tmpLastLoadDateMS) {
      tmpLastLoadDateMS = parseInt(tmpLastLoadDateMS);
      this.lastLoadDateMS = tmpLastLoadDateMS;
    }

    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      deltaRowDataMode: true,
      getRowNodeId: function (data) {
        return data.primaryKey;
      },
      // groupUseEntireRow: true,
      rowClassRules: {
        "row-new": (params) => params.api.getValue("new", params.node),
      },
    };

    function statusFormatter(state) {
      let newState = state;
      switch (state) {
        case "ToDo":
          newState = "Offen";
          break;
        case "Process":
          newState = "In Bearbeitung";
          break;
        case "Done":
          newState = "Geschlossen";
          break;
        case "WaitForInternAnswer":
          newState = "Warten auf interne Antwort";
          break;
        case "WaitForExternAnswer":
          newState = "Warten auf externe Antwort";
          break;
        default:
          break;
      }
      return newState;
    }

    function dateFormatter(date) {
      let newDate = new Date(date * 1000);
      let newDateString = newDate.toLocaleString();
      return newDateString;
    }

    function dateComperator(filterLocalDateAtMidnight, cellValue) {
      if (cellValue == null) {
        return 0;
      }
      let cellDate = new Date(cellValue * 1000);
      cellDate = new Date(
        cellDate.getFullYear(),
        cellDate.getMonth(),
        cellDate.getDate()
      );

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 120,
      sortable: true,
      resizable: true,
      filter: true,
    };
    this.columnDefs = [
      {
        headerName: this.$tc("ticket.pool", 1),
        field: "poolName",
        // rowGroup: true,
        // hide: true,
        minWidth: 160,
      },
      {
        headerName: this.$t("ticket.ticektCode"),
        field: "ticketCode",
        minWidth: 135,
      },
      {
        headerName: this.$t("ticket.subject"),
        field: "subject",
        minWidth: 200,
      },
      {
        headerName: this.$t("ticket.sender"),
        field: "ticketSender.emailAddress",
      },
      {
        headerName: this.$t("ticket.customer"),
        field: "customerName",
      },
      {
        headerName: this.$t("ticket.owner"),
        field: "ownerName",
      },
      // {
      //   headerName: "Agent",
      //   field: "agent",
      // },
      {
        headerName: this.$t("ticket.state"),
        field: "state",
        minWidth: 100,
        valueFormatter: (params) => statusFormatter(params.value),
      },
      // {
      //   headerName: "Prio",
      //   field: "",
      //   minWidth: 80,
      // },
      {
        headerName: this.$t("ticket.createdAt"),
        field: "creationDate",
        filter: "agDateColumnFilter",
        valueFormatter: (params) => dateFormatter(params.value),
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          comparator: dateComperator,
        },
      },
      {
        headerName: this.$t("ticket.lastChange"),
        field: "lastChangeDate",
        filter: "agDateColumnFilter",
        valueFormatter: (params) => dateFormatter(params.value),
        filterParams: {
          debounceMs: 500,
          suppressAndOrCondition: true,
          comparator: dateComperator,
        },
      },
      {
        headerName: "New",
        field: "new",
        width: 0,
        hide: true,
        suppressToolPanel: true,
      },
    ];
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    const sortModel = [{ colId: "creationDate", sort: "desc" }];
    this.gridApi.setSortModel(sortModel);

    this.getCountOfFilters();
  },
  beforeDestroy() {
    clearInterval(this.refreshTimer);
  },
};
</script>

<style lang="scss">
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

:root {
  --ag-background-color: #f1f4f0;
  --ag-odd-row-background-color: #f1f4f0;
}

#filterAndTable {
  display: flex;
  height: 100%;
}

#grid-wrapper {
  width: 100%;
  height: 100%;
}

.overviewParent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  height: 100%;
}

.ag-theme-alpine .ag-root-wrapper {
  border: 0;
}

.ag-theme-alpine {
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.row-new {
  font-weight: bold;
  //background-color: rgb(220, 220, 220) !important;
  -webkit-box-shadow: inset 10px 0px 0px 0px #1800FF; 
  box-shadow: inset 10px 0px 0px 0px #1800FF;
}

.filteWrapper {
  margin: 0.5em;
}

#filter {
  background: #fff;
  height: 100%;
  width: 15em;
  flex-shrink: 0;
  /* padding-left: 30px; */
  border-right: 1px solid #efefef;
  box-sizing: border-box;

  a {
    text-decoration: none;
    color: #286145;
    display: block;
    padding: 0.5em;
    transition: 0.1s;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
  }

  a:last-child {
    border: 0;
  }

  a:hover {
    cursor: pointer;
    background: #efefef;
    color: #286145;
  }

  a.currentFilter {
    /* font-weight: bold; */
    background: #333;
    color: #e4e4e4;
    /* border-left: 3px solid #E4E4E4; */
    /* box-shadow: inset 2px 0 0 #e4e4e4; */
  }

  a.poolFilter {
    margin-left: 1em;
  }

  a.currentPoolFilter {
    /* font-weight: bold; */
    background: #333;
    color: #e4e4e4;
    /* border-left: 3px solid #E4E4E4; */
    /* box-shadow: inset 2px 0 0 #e4e4e4; */
  }
}

.countOfFilter {
  float: right;
}

/* #filter a {
  text-decoration: none;
  color: #286145;
  display: block;
  padding: 0.5em;
  transition: 0.1s;
} */

.ag-row {
  cursor: pointer;
}
</style>
