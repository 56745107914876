import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import utils from '@/functions/utils';

var processFunctions = {
    async getCountOfFilters() {
        var ticketFilterCountOfs = null;

        await axios
            .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_COUNT_OF_BY_FILTER)
            .then((resp) => {
                ticketFilterCountOfs = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 500) {
                    error.handleGlobally && error.handleGlobally();
                } else if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        // if no ticketFilterCountOfs, try it again after some time, because first try after login is 401
        if (!ticketFilterCountOfs) {
            await utils.delay(500);
            await axios
                .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_COUNT_OF_BY_FILTER)
                .then((resp) => {
                    ticketFilterCountOfs = resp.data;
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        return ticketFilterCountOfs;
    },
    async getProcessesByFilter(filter) {
        var processes = null;

        await axios
            .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_PROCESS_BY_FILTER + filter)
            .then((resp) => {
                processes = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 500) {
                    error.handleGlobally && error.handleGlobally();
                } else if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        // if no processes, try it again after some time, because first try after login is 401
        if (!processes) {
            await utils.delay(500);
            await axios
                .get(process.env.VUE_APP_TICKET_SERVICE_API_URL_PROCESS_BY_FILTER + filter)
                .then((resp) => {
                    processes = resp.data;
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        return processes;
    },
    async loadProcess(processId, store) {
        store.dispatch(LOADING_STARTED);

        let ticketProcess = null;

        await axios
            .get(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_PROCESS_BY_ID +
                processId
            )
            .then((resp) => {
                // console.log("loadProcess resp: ", resp);
                ticketProcess = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return ticketProcess;
    },
    async updateProcess(ticketProcess, store) {
        store.dispatch(LOADING_STARTED);

        if (ticketProcess.ticketSender && ticketProcess.ticketSender.emailAddress) {
            ticketProcess.ticketSender.emailAddress = ticketProcess.ticketSender.emailAddress.toLowerCase();
        }

        await axios
            .put(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_PROCESS_SAVE,
                ticketProcess
            )
            .then(() => { })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);
    },
    async createProcess(ticketProcess, store) {
        store.dispatch(LOADING_STARTED);

        var primaryKey = null;

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL + "/api/process/create",
                ticketProcess
            )
            .then((resp) => {
                // console.log("createProcess resp: ", resp);
                // return primaryKey
                primaryKey = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return primaryKey;
    }
}

export default processFunctions;