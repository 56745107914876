import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from "../actions/auth";
import { LOADING_STARTED, LOADING_STOPPED } from '../actions/loading';
import { MENU_CLOSE } from "@/store/actions/menu";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotAllowedToEnterAppError } from "@/errors/NotAllowedToEnterAppError";

const state = {
    token: localStorage.getItem("user-token") || "",
    userId: parseInt(localStorage.getItem("user-id")) || -1,
    status: "",
    hasLoadedOnce: false
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    token: state => state.token,
    userId: state => parseInt(state.userId),
    isAdmin: state => {
        const decodedToken = jwt_decode(state.token);
        if (decodedToken) {
            if (decodedToken.group.includes(process.env.VUE_APP_ADMINISTRATOR_GROUP_NAME)) {
                return true;
            }
        }
        return false;
    }
};

async function executeLoginRequest(dispatch, commit, user, resolve, reject) {
    if (!user.name || !user.password) {
        console.log("The name or password is not set");
        return;
    }

    user.name = user.name.toLowerCase();
    user.name = user.name.trim();

    commit(AUTH_REQUEST);
    dispatch(LOADING_STARTED);

    await axios({ url: process.env.VUE_APP_USER_SERVICE_API_URL_USER_LOGIN, data: user, method: "POST" })
        .then(resp => {
            // console.log("Login successful resp: ", resp);
            const token = resp.data[0];
            // console.log("Login successful token: ", token);

            // get the userId
            const decodedToken = jwt_decode(token);
            // console.log("login decodedToken:", decodedToken);
            var userId = -1;
            if (decodedToken) {
                if (!decodedToken.group.includes(process.env.VUE_APP_APP_GROUP_NAME)) {
                    console.log("The user is not in the App Group");
                    throw new NotAllowedToEnterAppError("The user is not in the App Group");
                }
                userId = parseInt(decodedToken.userId);
                localStorage.setItem("user-id", userId);
            } else {
                console.error("An error occurred while decoding the jwt token");
                localStorage.removeItem("user-id");
                throw Error("An error occurred while decoding the jwt token.");
            }

            // set the token
            localStorage.setItem("user-token", token);
            // Set the default header in axios
            axios.defaults.headers.common['Authorization'] = "Bearer " + token;

            // set token and userId in state
            commit(AUTH_SUCCESS, { token, userId });
            resolve(resp);
        })
        .catch((error) => {
            commit(AUTH_ERROR, error);
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-id");
            // remove the axios default header
            delete axios.defaults.headers.common['Authorization'];
            reject(error);
        });
    dispatch(LOADING_STOPPED);
}

const actions = {
    [AUTH_REQUEST]: ({ dispatch, commit }, user) => {
        // console.log("auth_request");
        return new Promise((resolve, reject) => {
            // console.log("auth_request promise");
            executeLoginRequest(dispatch, commit, user, resolve, reject);
        });
    },
    [AUTH_LOGOUT]: ({ dispatch, commit }) => {
        return new Promise(resolve => {
            dispatch(MENU_CLOSE);
            commit(AUTH_LOGOUT);
            localStorage.removeItem("user-token");
            localStorage.removeItem("user-id");
            // remove the axios default header
            delete axios.defaults.headers.common['Authorization'];
            resolve();
        });
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
        state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, { token, userId }) => {
        state.status = "success";
        state.userId = userId;
        state.token = token;
        state.hasLoadedOnce = true;
    },
    [AUTH_ERROR]: state => {
        state.status = "error";
        state.hasLoadedOnce = true;
    },
    [AUTH_LOGOUT]: state => {
        state.userId = -1;
        state.token = "";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};