import {
    LOADING_STARTED,
    LOADING_STOPPED,
} from "../actions/loading";

var wasStarted = false;

const state = {
    status: "",
    loading: false,
};

const getters = {
    isLoading: state => state.loading,
};

const actions = {
    [LOADING_STARTED]: ({ commit }) => {
        commit(LOADING_STARTED);
        wasStarted = true;
    },
    [LOADING_STOPPED]: ({ commit }) => {
        wasStarted = false;
        setTimeout(function () {
            // console.log("wasStarted: ", wasStarted);
            if (!wasStarted) {
                commit(LOADING_STOPPED);
            }
        }, 500);

    }
};

const mutations = {
    [LOADING_STARTED]: state => {
        state.loading = true;
    },
    [LOADING_STOPPED]: (state) => {
        state.loading = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};