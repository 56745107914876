import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";

var ticketFunctions = {
    async loadTicket(ticketId) {
        let ticket = null;

        await axios
            .get(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_TICKET_BY_ID +
                ticketId
            )
            .then((resp) => {
                // console.log("loadTicket resp: ", resp);
                ticket = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        return ticket;
    },
    async addAnswer(ticketProcessPrimaryKey, ticketAnswer, store) {
        store.dispatch(LOADING_STARTED);

        let success = false;

        await axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_TICKET_ADD_ANSWER +
                ticketProcessPrimaryKey,
                ticketAnswer
            )
            .then(() => {
                // console.log("addAnswer resp: ", resp);
                success = true;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return success;
    },
    async searchTicket(searchInput) {
        let searchResult = null

        await axios
            .get(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_TICKET_SEARCH +
                searchInput
            )
            .then((resp) => {
                // console.log("searchTicket resp: ", resp);
                searchResult = resp.data
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally()
            })

        return searchResult
    },
    async uploadFile(file) {
        const formData = new FormData();

        formData.append("file", file);

        return axios
            .post(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_TICKET_UPLOAD_FILE,
                formData
            )
            .then((resp) => resp.data)
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });
    },
    async downloadFile(attachment, store) {
        store.dispatch(LOADING_STARTED);

        let blob = null;

        await axios
            .get(
                process.env.VUE_APP_TICKET_SERVICE_API_URL_TICKET_DOWNLOAD_FILE +
                attachment.name
                //+ "/" +
                // attachment.fileName
                ,
                {
                    responseType: 'arraybuffer'
                }
            )
            .then((resp) => {
                // console.log("resp: ", resp);
                blob = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return blob;
    }
}

export default ticketFunctions;