import {
    MENU_TOGGLE,
    MENU_CLOSE,
} from "../actions/menu";

const state = {
    status: "",
    showMenu: false,
};

const getters = {
    isMenuOpen: state => state.showMenu,
    isMenuClosed: state => !state.showMenu,
};

const actions = {
    [MENU_TOGGLE]: ({ commit }) => {
        commit(MENU_TOGGLE);
    },
    [MENU_CLOSE]: ({ commit }) => {
        commit(MENU_CLOSE);
    }
};

const mutations = {
    [MENU_TOGGLE]: state => {
        state.showMenu = !state.showMenu;
    },
    [MENU_CLOSE]: (state) => {
        state.showMenu = false;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};