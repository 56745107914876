import axios from 'axios';
import { LOADING_STARTED, LOADING_STOPPED } from "@/store/actions/loading";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import utils from '@/functions/utils';

var appGroupId = -1;

var userFunctions = {
    async isMemberOfAdminGroup(userId, store) {
        let isMember = await this.isMemberOfGroup(userId, Number(process.env.VUE_APP_ADMINISTRATOR_GROUP_ID), store);
        return isMember;
    },
    async addUserToAdminGroup(userId, store) {
        await userFunctions.addUsersToGroups([userId], [Number(process.env.VUE_APP_ADMINISTRATOR_GROUP_ID)], store);
    },
    async removeUserFromAdminGroup(userId, store) {
        await userFunctions.removeUsersFromGroups([userId], [Number(process.env.VUE_APP_ADMINISTRATOR_GROUP_ID)], store);
    },
    async addUsersToUsersGroup(userIds, store) {
        await userFunctions.addUsersToGroups(userIds, [Number(process.env.VUE_APP_USERS_GROUP_ID)], store);
    },
    async validUser(store, router, i18nRouteToLogin) {
        let isValid = false;

        if (store.getters.userId > 0) {
            store.dispatch(LOADING_STARTED);

            await axios
                .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/valid")
                .then((resp) => {
                    isValid = resp.data.valid;
                    // console.log("isValid: ", isValid);
                })
                .catch((error) => {
                    console.error(error, error.response);
                });

            // if not valid, try it again after some time
            if (!isValid) {
                await utils.delay(500);
                await axios
                    .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/valid")
                    .then((resp) => {
                        isValid = resp.data.valid;
                        // console.log("isValid retry: ", isValid);
                    })
                    .catch((error) => {
                        console.error(error, error.response);
                    });
            }

            store.dispatch(LOADING_STOPPED);
        }

        if (!isValid) {
            console.log("user: Got logged out because token is no longer valid");
            store.dispatch(AUTH_LOGOUT);
            router.push(i18nRouteToLogin);
            // TODO notify user that he gotted logged out because token is no longer valid
        }
    },
    async getUsers(userIds) {
        // if no ID is given request all user IDs
        if (userIds == null) {
            await axios
                .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/list")
                .then((resp) => {
                    // console.log("getUsers user/list resp: ", resp);
                    userIds = resp.data;
                })
                .catch((error) => {
                    const statusCode = error.response ? error.response.status : null;
                    if (statusCode !== 401) {
                        console.error(error, error.response);
                    }
                    // error.handleGlobally && error.handleGlobally();
                });

            // try again after some time if userIds are empty, because first try after login is 401
            if (!userIds || userIds.length <= 0) {
                await utils.delay(500);
                await axios
                    .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/list")
                    .then((resp) => {
                        // console.log("getUsers user/list resp: ", resp);
                        userIds = resp.data;
                    })
                    .catch((error) => {
                        console.error(error, error.response);
                        error.handleGlobally && error.handleGlobally();
                    });
            }
        } else if (!Array.isArray(userIds)) {
            // if only one value map to array
            userIds = [userIds];
        }

        if (userIds == null) {
            return null;
        }

        userIds = userIds.map(Number);

        // console.log("userIds after format: ", userIds);

        let users = null;
        var statusCode = 0;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/get", userIds)
            .then((resp) => {
                // console.log("getUsers user/get resp: ", resp);
                users = resp.data;
            })
            .catch((error) => {
                statusCode = error.response ? error.response.status : null;
                if (statusCode !== 401) {
                    console.error(error, error.response);
                }
                // error.handleGlobally && error.handleGlobally();
            });

        // if no users and 401 try again after some time
        if (!users && statusCode == 401) {
            await utils.delay(500);
            await axios
                .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/get", userIds)
                .then((resp) => {
                    // console.log("getUsers user/get resp: ", resp);
                    users = resp.data;
                })
                .catch((error) => {
                    console.error(error, error.response);
                    // error.handleGlobally && error.handleGlobally();
                });
        }

        return users;
    },
    async getAppUsers(store) {
        var appGroup = await this.getAppGroup(store);
        if (appGroup) {
            return await this.getUsers(appGroup.userIds);
        } else {
            return null;
        }
    },
    async createUsers(users, store) {
        store.dispatch(LOADING_STARTED);

        var userIds = null;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/add", users)
            .then((resp) => {
                userIds = resp.data;
                if (userIds) {
                    for (let i = 0; i < users.length; i++) {
                        const user = users[i];
                        user.id = userIds[i];
                    }
                }
            })
            .catch((error) => {
                store.dispatch(LOADING_STOPPED);
                console.error(error, error.response);
                error.handleGlobally && error.handleGlobally();
                throw error;
            });

        await this.addUsersToUsersGroup(userIds, store);

        store.dispatch(LOADING_STOPPED);

        return userIds;
    },
    async createAppUsers(users, store) {
        // console.log("createAppUsers users:", users);
        await this.createUsers(users, store);

        // console.log("createAppUsers appGroupId:", appGroupId);

        if (appGroupId < 0) {
            if (!(await this.getAppGroup(store))) {
                // console.log("no app group found, create it");
                await this.createAppGroup(store);
            }
        }
        // console.log("createAppUsers appGroupId after get or create:", appGroupId);

        var userIds = users.map((user) => user.id);
        await this.addUsersToAppGroup(userIds, store);
    },
    async updateUsers(users, store) {
        store.dispatch(LOADING_STARTED);

        var userIds = null;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/modify", users)
            .then((resp) => {
                userIds = resp.data;
            })
            .catch((error) => {
                console.error(error, error.response);
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return userIds;
    },
    async getUserArbitrary(store, userIds) {
        // console.log("getUserArbitrary userIds: ", userIds);
        store.dispatch(LOADING_STARTED);

        if (!Array.isArray(userIds)) userIds = [userIds];
        // console.log("isarray userIds: ", userIds);

        userIds = userIds.map(Number);
        // console.log("map userIds: ", userIds);

        let arbitrary = null;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/arbitrary", userIds)
            .then((resp) => {
                // console.log("getUserArbitrary resp: ", resp);
                arbitrary = resp.data;

                // for (let arbObj in arbitrary) {
                //     try {
                //         arbObj = JSON.parse(arbObj.object);
                //     } catch (error) {
                //         error.handleGlobally && error.handleGlobally();
                //     }
                // }
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 401) {
                    console.error(error, error.response);
                }
                // error.handleGlobally && error.handleGlobally();
            });

        // if no arbitrary, try it again after some time, because first try after login is 401
        if (!arbitrary) {
            await utils.delay(500);
            await axios
                .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/user/arbitrary", userIds)
                .then((resp) => {
                    // console.log("getUserArbitrary resp: ", resp);
                    arbitrary = resp.data;

                    // for (let arbObj in arbitrary) {
                    //     try {
                    //         arbObj = JSON.parse(arbObj.object);
                    //     } catch (error) {
                    //         error.handleGlobally && error.handleGlobally();
                    //     }
                    // }
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        store.dispatch(LOADING_STOPPED);

        // console.log("getUserArbitrary arbitrary: ", arbitrary);

        return arbitrary;
    },
    async getGroupIdsByFilter(filter, store) {
        store.dispatch(LOADING_STARTED);

        let groupIds = null;

        await axios
            .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/list/" + filter)
            .then((resp) => {
                // console.log("getGroupIdsByFilter resp:", resp);
                groupIds = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        // if no groupIds, try it again after some time, because first try after login is 401
        if (!groupIds) {
            await utils.delay(1000);
            await axios
                .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/list/" + filter)
                .then((resp) => {
                    // console.log("getGroupIdsByFilter resp:", resp);
                    groupIds = resp.data;
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        store.dispatch(LOADING_STOPPED);

        return groupIds;
    },
    async getGroups(groupIds, store) {
        store.dispatch(LOADING_STARTED);

        let groups = null;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/get/", groupIds)
            .then((resp) => {
                // console.log("getGroups resp:", resp);
                groups = resp.data;
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode !== 401) {
                    console.error(error, error.response);
                }
            });

        // if no groups, try it again after some time, because first try after login is 401
        if (!groups) {
            await utils.delay(500);
            await axios
                .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/get/", groupIds)
                .then((resp) => {
                    // console.log("getGroups resp:", resp);
                    groups = resp.data;
                })
                .catch((error) => {
                    error.handleGlobally && error.handleGlobally();
                });
        }

        store.dispatch(LOADING_STOPPED);

        return groups;
    },
    async getGroupByName(groupName, store) {
        var groupIds = await this.getGroupIdsByFilter(groupName, store);
        // console.log("getGroupByName goupIds:", groupIds);
        if (!groupIds) {
            return null;
        }
        var groups = await this.getGroups(groupIds, store);
        // console.log("getGroupByName groups:", groups);

        var foundGroup = null;
        if (groups && groups.length > 1) {
            for (const group of groups) {
                if (group.name == groupName) {
                    foundGroup = group;
                    break;
                }
            }
        } else if (groups && groups.length == 1) {
            foundGroup = groups[0];
        }

        return foundGroup;
    },
    async getAppGroup(store) {
        var foundGroup = await this.getGroupByName(process.env.VUE_APP_APP_GROUP_NAME, store);
        // console.log("getAppGroup foundGroup:", foundGroup);
        if (foundGroup) {
            appGroupId = foundGroup.id;
        }
        return foundGroup;
    },
    async creeateGroups(groups, store) {
        // console.log("creeateGroups groups:", groups);
        store.dispatch(LOADING_STARTED);

        var groupIds = null;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/add/", groups)
            .then((resp) => {
                // console.log("creeateGroups resp:", resp);
                groupIds = resp.data;
                if (groupIds) {
                    for (let i = 0; i < groups.length; i++) {
                        const group = groups[i];
                        group.id = groupIds[i];
                    }
                }
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return groupIds;
    },
    async createAppGroup(store) {
        var appGroup = new Object();
        appGroup.name = process.env.VUE_APP_APP_GROUP_NAME;
        // console.log("createAppGroup appGroup:", appGroup);
        await this.creeateGroups([appGroup], store);
        // console.log("createAppGroup appGroupId:", appGroup.id);
        appGroupId = appGroup.id;
        return appGroup.id;
    },
    async isMemberOfGroup(userId, groupId, store) {
        store.dispatch(LOADING_STARTED);

        let isMember = false;

        await axios
            .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/isMember/" + userId + "/" + groupId)
            .then((resp) => {
                // console.log("isMemberOfGroup: ", resp);
                if (resp.status == 200) {
                    isMember = true;
                }
            })
            .catch((error) => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode === 418) {
                    console.log("418 seems to means that user ", userId, " is not member of group ", groupId);
                } else if (statusCode === 401) {
                    // console.error(error, error.response);
                } else {
                    error.handleGlobally && error.handleGlobally();
                }
            });

        // if not member, try it again after some time, because first try after login is 401
        if (!isMember) {
            await utils.delay(500);
            await axios
                .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/isMember/" + userId + "/" + groupId)
                .then((resp) => {
                    if (resp.status == 200) {
                        isMember = true;
                    }
                })
                .catch((error) => {
                    console.error(error, error.response);
                    error.handleGlobally && error.handleGlobally();
                });
        }

        store.dispatch(LOADING_STOPPED);

        return isMember;
    },
    async loadGroupsOfUser(userId, store) {
        store.dispatch(LOADING_STARTED);

        let groups = null;

        await axios
            .get(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/for/" + userId)
            .then((resp) => {
                // console.log("loadGroupsOfUser: ", resp);
                groups = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return groups;
    },
    async loadUsersOfGroups(groups, store) {
        store.dispatch(LOADING_STARTED);

        let users = null;

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/listUsers", groups)
            .then((resp) => {
                // console.log("loadUsersOfGroups: ", resp);
                users = resp.data;
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);

        return users;
    },
    async addUsersToGroups(users, groups, store) {
        store.dispatch(LOADING_STARTED);

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/addUsers", { users, groups })
            .then(() => {
                // console.log("addUsersToGroups: ", resp);
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);
    },
    async addUsersToAppGroup(userIds, store) {
        if (appGroupId < 0) {
            await this.getAppGroup(store);
        }
        if (appGroupId >= 0) {
            await this.addUsersToGroups(userIds, [appGroupId], store);
        } else {
            console.error("user was not added to appGroup because appGroupId was not set");
        }
    },
    async removeUsersFromGroups(users, groups, store) {
        store.dispatch(LOADING_STARTED);

        await axios
            .post(process.env.VUE_APP_USER_SERVICE_API_URL + "/group/removeUsers", { users, groups })
            .then(() => {
                // console.log("removeUsersFromGroups: ", resp);
            })
            .catch((error) => {
                error.handleGlobally && error.handleGlobally();
            });

        store.dispatch(LOADING_STOPPED);
    },
}

export default userFunctions;