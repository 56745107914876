<template>
  <div id="app">
    <Spinner v-if="$store.getters.isLoading"></Spinner>

    <div class="mainApp">
      <div class="top-bar" v-if="$store.getters.isAuthenticated">
        <span class="menu-button icon-button" @click="toggleMenu()"
          ><font-awesome-icon icon="bars"
        /></span>
      </div>

      <SearchMenu
        v-if="$store.getters.isAuthenticated"
        v-bind:class="{ collapsed: $store.getters.isMenuClosed }"
        class="menu"
      />

      <router-view class="router" />
    </div>
    <div class="footer">
      <!-- <span>© <b>ZECK GmbH</b>. Alle Rechte vorbehalten.</span> -->
      <router-link class="footerLink" :to="$i18nRoute({ name: 'Imprint' })">
        {{ $t("imprint.imprint") }}
      </router-link>
      <router-link class="footerLink" :to="$i18nRoute({ name: 'Dataprotection' })">
        {{ $t("dataprotection.dataprotection") }}
      </router-link>
      <router-link class="footerLink" :to="$i18nRoute({ name: 'Agb' })"> {{ $t("agb.agb") }} </router-link>
      <router-link class="footerLink" :to="$i18nRoute({ name: 'Copyright' })">
        {{ $t("copyright.copyright") }}
      </router-link>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import Spinner from "@/components/Spinner.vue";
import SearchMenu from "@/components/SearchMenu";
import { MENU_CLOSE, MENU_TOGGLE } from "@/store/actions/menu";
import userFunctions from "@/functions/user";

import { Hidden } from "vue-grid-responsive";
import axios from "axios";
Vue.component("hidden", Hidden);

export default {
  name: "Ticket",
  components: {
    Spinner,
    SearchMenu,
  },
  watch: {
    $route() {
      // close menu when the route has changed
      this.$store.dispatch(MENU_CLOSE);
    },
  },
  methods: {
    toggleMenu() {
      this.$store.dispatch(MENU_TOGGLE);
    },
    wakeUpServer() {
      // console.log("Try to wake up the servers");
      axios.get(
        process.env.VUE_APP_TICKET_SERVICE_API_URL + "/swagger/index.html"
      );
      axios.get(process.env.VUE_APP_USER_SERVICE_URL + "/swagger/index.html");
    },
  },
  beforeCreate() {
    // console.log("App beforeCreeate validUser");
    userFunctions.validUser(this.$store, this.$router, this.$i18nRoute({name: "Login"}));
  },
  beforeMount() {
    this.wakeUpServer();
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  mounted() {
    // console.log("env: ", process.env.NODE_ENV);
    // console.log(
    //   "process.env.VUE_APP_TICKET_SERVICE_API_URL: ",
    //   process.env.VUE_APP_TICKET_SERVICE_API_URL
    // );
  },
};
</script>

<style lang="scss">
html {
  // create a cover image, to fix iPhone notch
  padding: env(safe-area-inset);
}

body {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

#app {
  overflow: hidden;
  font-family: "Gotham", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-size: 16px;
  zoom: 80%;
}

.mainApp {
  display: flex;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.footer {
  background: #286145;
  text-align: right;

  .footerLink {
    color: #e4e4e4;
    // text-decoration: none;
    padding: 0.25em 0.5em;
    display: inline-block;
    transition: 0.1s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.menu {
  width: 15em;
  flex-shrink: 0;
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.top-bar {
  display: none;
  background: #222;
  color: #e4e4e4;
  // padding: .5em;

  .menu-button {
    display: inline-flex;
    font-size: 2em;
    padding: 0.25em;
    color: #e4e4e4;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .mainApp {
    flex-direction: column;
  }

  .menu {
    // width: 100%;
    position: absolute;
    // display: none;

    .companyLogo {
      margin-top: 40px;
    }

    &.collapsed {
      display: none;
    }
  }

  .top-bar {
    display: block;
    z-index: 1000;
  }
}

.router {
  flex-grow: 1;
  overflow: hidden;
}
</style>
